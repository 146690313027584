<!-- =========================================================================================
  File Name: View.vue
  Description: Homestay View page
  ----------------------------------------------------------------------------------------
  Item Name: Booking manager admin
    Author: HopNepal
    Author URL: https://www.hopnepal.com
========================================================================================== -->

<template>
  <div id="page-user-view">

    <vs-alert color="danger" title="Homestay Not Found" :active.sync="package_not_found">
      <span>Package record with id: {{ $route.params.packageId }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{name:'packages'}" class="text-inherit underline">All Packages</router-link>
      </span>
    </vs-alert>

    <div id="user-data">

      <vx-card title="Package Details" class="mb-base">
        <!-- Avatar -->
        <div class="vx-row">
          <!-- Avatar Col -->
          <div class="vx-col" id="avatar-col">
            <div class="img-container mb-4">
              <img class="rounded w-full" :src="package_image" v-if="package_image != ''"/>
            </div>
          </div>

          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-1">
            <table>
              <tr>
                <td class="font-semibold">Package Name</td>
                <td>{{form.name}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Vendor Name</td>
                <td>{{dropdown.vendor_id.label}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Region</td>
                <td>{{dropdown.region_id.label}}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 1 -->

          <!-- Information - Col 2 -->
          <div class="vx-col flex-1" id="account-info-col-2">
            <table>
              <tr>
                <td class="font-semibold">Package Type</td>
                <td>{{dropdown.package_type.label}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Activity Type</td>
                <td>{{dropdown.activity_id.label}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Departure Type</td>
                <td>{{dropdown.departure_type.label}}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 2 -->
          <div class="vx-col w-full flex" id="account-manage-buttons">
            <vs-button icon-pack="feather" icon="icon-edit" class="mr-4" :to="{name: 'packages.edit', params: { packageId: $route.params.packageId }}">Edit</vs-button>
            <vs-button type="border" color="danger" icon-pack="feather" icon="icon-trash" @click="confirmDeleteRecord">Delete</vs-button>
          </div>

        </div>

      </vx-card>

      <vx-card title="Package Images">
        <p>You can add only one image as featured image</p>
        <vs-table :data="package_images">
            <template slot="thead">
                <vs-th>Image</vs-th>
                <vs-th>Alt Text</vs-th>
                <vs-th>Featured Image</vs-th>
                <vs-th>Action</vs-th>
            </template>
            <template>
                <vs-tr :key="indextr" v-for="(tr, indextr) in package_images">
                    <vs-td><img class="featured-images" :src="tr.url" /></vs-td>
                    {{tr.alt}}
                    <vs-td><vs-switch :value="tr.featured" @input="changeStatus($event, tr.id)" :disabled="tr.featured" /></vs-td>
                    <vs-td><vs-button type="border" color="danger" icon-pack="feather" icon="icon-trash" @click="confirmDeletePackageImage(tr.id)"></vs-button></vs-td>
                </vs-tr>
            </template>
        </vs-table>
      </vx-card>
      
    <vx-card title="Itenaries" class="mb-base">
        <!-- Avatar -->
        <div class="vx-row">
            <!-- /Information - Col 2 -->
            <div class="vx-col w-full flex" id="account-manage-buttons">
                <vs-button icon-pack="feather" icon="icon-plus" class="mr-4" :to="{name: 'packages.itenaries.create', params: { userId: $route.params.packageId }}">Create</vs-button>
            </div>
            <div id="page-user-list">
                <div class="vx-card p-6">
                <!-- AgGrid Table -->
                    <ag-grid-vue
                    ref="agGridTable"
                    :components="components"
                    class="ag-theme-material w-100 my-4 ag-grid-table"
                    :columnDefs="columnRoomsDefs"
                    :defaultColDef="defaultRoomsColDef"
                    :rowData="itenaries.data"
                    rowSelection="multiple"
                    colResizeDefault="shift"
                    :animateRows="true"
                    :floatingFilter="true"        
                    :suppressPaginationPanel="true"
                    :enableRtl="$vs.rtl">
                    </ag-grid-vue>
                </div>
            </div>
        </div>
    </vx-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AgGridVue } from "ag-grid-vue"
import vSelect from 'vue-select'
import {destroyImage, makeFeaturedImage} from '../../../services/image';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import loaderMixin from '../../../mixins/loaderMixin';

// Cell Renderer
import CellRendererActions from "../itenaries/page/cell-render/CellRendererActions.vue"

export default {
    components: {
        AgGridVue,
        vSelect,
        CellRendererActions
    },
  mixins: [loaderMixin],
    computed: {
        ...mapGetters({
            form: 'main_package/getFormObj',
            package_image: 'main_package/getImageUrl',
            package_images: 'main_package/getImages',
            dropdown: 'main_package/getFormDropdownObj',
            itenaries: 'main_package/getFromPackageItenaries',
        }),
        getPackageId() {
            return this.$route.params.packageId;
        },
    },
    created() {
      this.openLoading();
      this.fetchAndSetPackageById(this.getPackageId)
          .then((res) => this.closeLoading())
          .catch((error) => this.closeLoading());
    },
    methods: {
        ...mapActions('main_package', [
            'deletePackageAction',
            'fetchAndSetPackageById'
        ]),
        confirmDeleteRecord() {
          this.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: `Confirm Delete`,
            text: `You are about to delete record with id: "${this.getPackageId}"`,
            accept: this.deleteRecord,
            acceptText: "Delete"
          })
        },
        deleteRecord() {
          this.openLoading();
          this.deletePackageAction(this.getPackageId)
            .then(() => this.deletedSuccessfully()).catch((error) => this.closeLoading());
        },
        deletedSuccessfully() {
          this.closeLoading();
          this.$vs.notify({
            color: 'success',
            title: 'Package Deleted',
            position: 'top-right',
            text: 'The selected package was successfully deleted'
          });
          this.$router.push("/packages");
        },

      confirmDeletePackageImage($id){
          this.imageId = $id;
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: `Confirm Delete`,
          text: `You are about to delete image with id: "${$id}"`,
          accept: this.deletePackageImage,
          acceptText: "Delete"
        });
      },
      deletePackageImage(){
        destroyImage(this.imageId).then((response) => {
            if(response.data.code === 204){
              this.openLoading();
              this.fetchAndSetPackageById(this.getPackageId)
                  .then((res) => this.closeLoading()).catch((r) => this.closeLoading());
                this.$vs.notify({ 
                    text: 'Package Images Deleted successfully.',
                    title: 'Package Images Deleted',
                    color: 'success',
                    position: 'top-right'
                });
            }
        });
      },
      
      changeStatus($event, imageId){
        this.openLoading();
        makeFeaturedImage({id: imageId, status: $event})
          .then(response => {
            if(response.status === 200) {
              this.fetchAndSetPackageById(this.getPackageId)
            .then((res) => this.closeLoading()).catch((r) => this.closeLoading());
              this.$vs.notify({ 
                  title: 'Package Images',
                  text: 'Package Images selected as featured image successfully.',
                  color: 'success',
                  position: 'top-right'
              });
            }
          }).catch((r) => this.closeLoading());
      }
    },
     data() {
        return {
            imageId: '',
            package_not_found: false,
            defaultRoomsColDef: {
                sortable: true,
                resizable: true,
                suppressMenu: true
            },
            columnRoomsDefs: [
                {
                headerName: 'ID',
                field: 'id',
                filter: true,
                },
                {
                headerName: 'Title',
                field: 'title',
                filter: true,
                width: 550,
                },
                {
                headerName: 'Actions',
                field: 'transactions',
                cellRendererFramework: 'CellRendererActions',
                },
            ],

            // Cell Renderer Components
            components: {
                CellRendererActions
            }
        }
    },
}

</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}
.amenity-base{
    margin: 5px;
    background: #292d49;
    color: #fff;
}
.danger{
    background: #802424;
    color: #fff;
}

.success{
    background: #259972;
    color: #fff;
}

.featured-images{
  height: 50px;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }
  }
}


@media screen and (min-width:1201px) and (max-width:1211px),
only screen and (min-width:636px) and (max-width:991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

}

#page-user-list {
    width: 100%;
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

</style>
